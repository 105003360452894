class IntersectionObserverClass {
	constructor() {
		const thresholdList = [0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 0.96, 0.97, 0.98, 0.99, 1];
		this.observerOptions = {
			root: null,
			rootMargin: '0px',
			threshold: thresholdList
		};
		this._observers = new Map();
	}

	/**
	 * isElementFullyVisible
	 * @param {object} entries intersectionObserver entries
	 * @returns {boolean} visibility - returns if Element is fully visible
	 */
	static isElementFullyVisible(entries) {
		let visibility = false;
		if (entries.length >= 0) {
			visibility = (entries[0].intersectionRatio || 0) >= 1;
		}
		return visibility;
	}

	/**
	 * isElementVisible
	 * @param {object} entries intersectionObserver entries
	 * @returns {boolean} visibility - returns if Element is partly visible
	 */
	static isElementVisible(entries) {
		let visibility = false;
		if (entries.length >= 0) {
			visibility = (entries[0].intersectionRatio || 0) > 0;
		}
		return visibility;
	}

	/**
	 * getElementsVisibilityPercentage
	 * @param {object} entries intersectionObserver entries
	 * @returns {number} percentage - percentage element is visible in root
	 */
	static getElementsVisibilityPercentage(entries) {
		let percentage = 0;
		if (entries.length >= 0) {
			const threshold = entries[0].intersectionRatio || 0;
			percentage = (threshold * 100).toFixed(2);
		}
		return percentage;
	}

	/**
	 * registerObserver - create intersection observer for specific element and and observer to observers map
	 * @param {HTMLElement} element element to be observed
	 * @param {function} callback callback function to be called on intersection
	 * @param {object} options intersectionObserver option - {root, rootMargin, threshold} will be merged with class´s observerOptions
	 * @returns {void} void
	 */
	registerObserver(element, callback, options = {}) {
		if (element && callback) {
			const observerOptions = Object.assign({}, this.observerOptions, options);
			const observer = new IntersectionObserver(callback, observerOptions);
			observer.observe(element);
			this._observers.set(element, observer);
		}
	}

	/**
	 * unregisterObserver - disconnects observer for specific element and removes observer from the observers map
	 * @param {HTMLElement} element elment which is used as key in observers map
	 * @returns {void} void
	 */
	unregisterObserver(element) {
		if (element) {
			const observer = this._observers.get(element);
			if (observer) {
				observer.disconnect();
				this._observers.delete(element);
			}
		}
	}
}

const intersectionObserver = new IntersectionObserverClass();

export {intersectionObserver, IntersectionObserverClass};
