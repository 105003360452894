const IS_IE_11 = !!navigator.userAgent.match(/Trident\/7\./);

class LazyLoadClass {
	constructor() {
		this._initializeLazyload();
	}

	/**
	 * _initializeLazyload
	 * @private
	 * @returns {void} void
	 */
	_initializeLazyload() {
		const observerOptions = {
			root: null,
			rootMargin: '50px 50px 50px 50px',
			threshold: [0.5]
		};
		this.intersectionObserver = new IntersectionObserver(this._intersectionHandler.bind(this), observerOptions);
		if (IS_IE_11) {
			this.intersectionObserver.POLL_INTERVAL = 100;
		}
	}

	/**
	 * getter for defaults
	 * @static
	 * @returns {object} defaults
	 */
	static get defaults() {
		return {
			lazyImage: 'sc-j-lazy-load-image',
			lazyImageClass: '.sc-j-lazy-load-image',
			srcSetAttribute: 'data-srcset',
			imgSrcAttribute: 'data-image-src',
			imgPendingAttribute: 'data-pending'
		};
	}

	/**
	 * register - has to be called on connectedCallback or domReady with context in which images should be loaded lazy
	 * @param {HTMLElement} element element
	 * @param {String} rootMargin rootMargin
	 * @returns {void}
	 */
	register(element) {
		const imagesToLoad = [...element.querySelectorAll(LazyLoadClass.defaults.lazyImageClass)];
		imagesToLoad.forEach(image => {
			image.classList.remove(LazyLoadClass.defaults.lazyImage);
			this.intersectionObserver.observe(image);
		});
	}

	/**
	 * reset images Sources and trigger loading via browser
	 * @param {HTMLElement} picture - <picture> element
	 * @returns {void} nothing
	 */
	async _resetImageSources(picture) {
		return new Promise((resolve, reject) => {

			const {sourceTags, imageTag} = this._getChildTags(picture);

			imageTag.onload = () => {
				this._removeLazyStyles(picture);
				imageTag.removeAttribute(LazyLoadClass.defaults.imgSrcAttribute);
				resolve('loaded');
			};

			imageTag.onerror = (error) => {
				console.log(error);
				this._removeLazyStyles(picture);
				imageTag.removeAttribute(LazyLoadClass.defaults.imgSrcAttribute);
				reject(error);
			};

			sourceTags.forEach(sourceTag => {
				sourceTag.setAttribute('srcset', sourceTag.getAttribute(LazyLoadClass.defaults.srcSetAttribute));
				sourceTag.removeAttribute(LazyLoadClass.defaults.srcSetAttribute);
			});

			imageTag.src = imageTag.getAttribute(LazyLoadClass.defaults.imgSrcAttribute);
		});
	}

	/**
	 * _intersectionHandler
	 * @param {Array<Object>} entries intersected entries
	 * @private
	 * @returns {void} void
	 */
	_intersectionHandler(entries = []) {
		entries.forEach(entry => {
			if ((entry.isIntersecting || entry.intersectionRatio > 0) && (entry.target.dataset.pending !== 'true')) {
				entry.target.dataset.pending = 'true';
				if (!!entry.target.style.backgroundImage) {
					this._loadAndSetImage(entry.target);
				}
				else {
					this._resetImageSources(entry.target);
				}
			}
		});
	}

	/**
	 * _loadAndSetImage
	 * @param {Object} image intersected image
	 * @returns {Promise<void>} void
	 */
	async _loadAndSetImage(image) {
		try {
			await this._setImageSource(image);
		}
		catch (e) {
			console.info(e);
		}
		finally {
			image.removeAttribute(LazyLoadClass.defaults.imgSrcAttribute);
			image.removeAttribute(LazyLoadClass.defaults.imgPendingAttribute);
			this.intersectionObserver.unobserve(image);
		}
		return image;
	}

	/**
	 * _removeLazyStyles
	 * @param {HTMLElement} element element
	 * @private
	 * @returns {void} void
	 */
	_removeLazyStyles(element) {
		element.classList.remove('sc-lazyload-blur');
		element.classList.remove('sc-j-lazyload-spinner');
	}

	/**
	 * handle image element preloading and source switching
	 * @param {HTMLElement} image - image
	 * @param {Boolean} isBackgroundImage isBackgroundImage
	 * @returns {Promise<HTMLImageElement>} nothing
	 */
	async _setImageSource(image) {
		const src = image.getAttribute(LazyLoadClass.defaults.imgSrcAttribute);
		let urlToLoad;
		if (!src) {
			throw new Error(`Element has no ${LazyLoadClass.defaults.imgSrcAttribute}`);
		}
		else {
			await this._preloadImage(src);
			urlToLoad = src;
		}

		this._setBackgroundImageSource(image, urlToLoad);
		this._removeLazyStyles(image);
		return image;
	}

	_setBackgroundImageSource(image, urlToLoad) {
		image.style.backgroundImage = `url(${urlToLoad})`;
	}

	/**
	 * _preloadImage
	 * @param {String} source image source to be loaded
	 * @returns {Promise<HTMLImageElement>} image
	 * @private
	 */
	_preloadImage(source = '') {
		return new Promise((resolve, reject) => {
			const image = new Image();

			image.onload = () => {
				resolve(image);
			};
			image.onerror = (error) => {
				reject(error);
			};

			image.src = source;
		});
	}

	/**
	 * gather picture element´s <source> and <img> tags
	 * @param {HTMLPictureElement} pictureElement - picture to gather elements from
	 * @returns {{sourceTags: Array, imageTag: *}} object with collected img and sources
	 * @private
	 */
	_getChildTags(pictureElement) {
		const sourceTags = [];
		let imageTag = pictureElement;

		if (pictureElement) {
			[...pictureElement.children].forEach(child => {
				if (child.tagName === 'SOURCE') {
					sourceTags.push(child);
				}
				else if (child.tagName === 'IMG') {
					imageTag = child;
				}
			});
		}

		return {sourceTags, imageTag};
	}
}

const lazyLoad = new LazyLoadClass();
export {lazyLoad, LazyLoadClass};
