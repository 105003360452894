import {router} from 'core-application';
import {dom} from 'core-utils';
import {SELECTORS, STCK_STORE} from "@oneaudi/stck-store";

class ScPageClass {
	static get defaults() {
		return {
			filterHashRegEx: (/[#|&]{1}(filter=)[^&]+/gi),
			presetHashRegEx: (/[#|&]{1}(preset=)[^&]+/gi),
			keepFilterHashRegEx: (/[#|&]{1}(keepFilters=)[^&]+/gi),
			cleanHashRegEx: (/[#|&]{1}(filter=|preset=|keepFilters=|altSort=|action=|searchid=|pid=|csref=|openDealerForm=)[^&]+/gi)
		};
	}

	/**
	 * get previous page-data from router history
	 * @returns {Object} page object
	 *@example
	 * {
	 *   pathName: location_.pathname,
	 *   hashIdentifier: ((location_.hash && location_.hash.indexOf("page=") >= 0) ? 'page' : ''),
	 *   hashShort: ((location_.hash && location_.hash.indexOf("page=") >= 0) ? location_.hash.split("page=")[1] : ''),
	 *   hash: location_.hash,
	 *   url: location_.pathname + location_.hash
	 *   }
	 */
	get previousPageObject() {
		return router.getPreviousPage();
	}

	/**
	 * remove entry hash from window.location
	 * @returns {void} nothing
	 */
	cleanupFilterHash() {
		const hash = window.location.hash;
		const hasFilterHashMatch = hash.match(ScPageClass.defaults.cleanHashRegEx);

		if (hasFilterHashMatch && hasFilterHashMatch.length) {
			if (window.history !== undefined && window.history.pushState !== undefined) {
				history.pushState('', document.title, window.location.pathname + window.location.search);
			}
		}
	}

	/**
	 * read filters/presets from hash
	 * removes # or & followed by 'filter=' returning the pure filters/presets
	 * @param {string} hash_ - window.location.hash
	 * @param {string} type_ - "filter" or => preset
	 * @return {string|null} filterstring
	 */
	getFilterHash(hash_, type_) {
		// read filter hash
		// eslint-disable-next-line no-nested-ternary
		var regex = type_ === 'filter' ? ScPageClass.defaults.filterHashRegEx : type_ === 'keepFilters' ? ScPageClass.defaults.keepFilterHashRegEx : ScPageClass.defaults.presetHashRegEx;
		let match = hash_.match(regex);
		if (match && match.length) {
			// remove # or & followed by 'filter=' OR 'preset= OR keepFilters=' returning the pure filters
			match = match[0].replace(/[#|&](filter=|preset=|keepFilters=)/gi, '');
		}
		return match || null;
	}

	/**
	 * checks if its stockcar details url
	 * @param {string} url_ - url to be checked
	 * @return {boolean} true/false
	 */
	isDetailsPage(url_ = location.href) {
		//  sc-favorite-items
		const isDetailPage_ =
			url_.indexOf('sc_detail') > -1 ||
			url_.indexOf('sc_detail_fav') > -1,
			isOverviewOrDetail_ =
				dom.isElement('.sc-result-items') ||
				dom.isElement('.sc-favorite-items') ||
				dom.isElement('.sc-detail-module') ||
				dom.isElement('.sc-j-compare-view-component');
		return !!url_ && isDetailPage_ && isOverviewOrDetail_;
	}

	/**
	 * checks if its stockcar result page
	 * @return {boolean} true/false
	 */
	isResultsPage() {
		const areaContent = dom.getElement('.nm-area-content');
		if (dom.isElement(areaContent)) {
			return areaContent.getAttribute('data-template') === 'results';
		}
		return false;
	}

	/**
	 * checks if its stockcar favorites page
	 * @return {boolean} true/false
	 */
	isFavoritesPage() {
		const favoritesView = dom.getElement('.sc-favorites-view');
		return dom.isElement(favoritesView);
	}

	/**
	 * checks if its stockcar favorites page
	 * @return {boolean} true/false
	 */
	isComparePage() {
		const compareView = dom.getElement('.sc-j-compare-view-component');
		return dom.isElement(compareView);
	}

	/**
	 * simulateNmPageOpen calls router to open page with ajax
	 * @param {string} url url target
	 * @return {void} returns nothing
	 */
	simulateNmPageOpen(url) {
		if (!!url) {
			router.open('page', url);
		}
	}

	/**
	 * simulateNmLayerLink calls router to open cms layer
	 * @param {string} url url target
	 * @return {void} returns nothing
	 */
	simulateNmLayerLink(url) {
		if (!!url) {
			router.open('layer', url);
		}
	}

	/**
	 * check if page contains filter (!detail-page, !favorites-page, !compage-page)
	 * @returns {boolean} filter page or not
	 */
	isFilterPage() {
		return dom.isElement(dom.getElement('.sc-module-main-navigation'));
	}

	/**
	 * check if page is a stockcar page
	 * @returns {boolean} stck page or not
	 */
	isStockcarPage() {
		return dom.isElement(dom.getElement('.sc-content')) || dom.isElement(dom.getElement('.sc-module'));
	}

	/**
	 * checks if an element is inside layer context
	 * @param {HTMLElement} element - dom css selector
	 * @returns {boolean} is in layer context or not
	 */
	isElementInOpenedLayerContext(element) {
		if (!element) {
			console.error('No element passed');
			return false;
		}
		const layer = document.querySelector('.nm-layer-opened .nm-layer');

		return !!(layer && layer.contains(element));
	}

	/**
	 * checks if an element is inside layer context
	 * @param {HTMLElement} element - dom css selector
	 * @returns {boolean} is in layer context or not
	 */
	isElementInLayerContext(element) {
		if (!element) {
			console.error('No element passed');
			return false;
		}
		const layer = document.querySelector('.nm-layer');

		return !!(layer && layer.contains(element));
	}

	/**
	 * checks if an element is inside modal layer context
	 * @param {HTMLElement} element - dom css selector
	 * @returns {boolean} is in modal layer context or not
	 */
	isElementInModalLayerContext(element) {
		if (!element) {
			console.error('No element passed');
			return false;
		}
		const layer = document.querySelector('.modal-layer[data-layer-active=true]');

		return !!(layer && layer.contains(element));
	}

	/**
	 * checks if vehicle is available soon
	 * @param {object} vehicle - vehicle data
	 * @returns {boolean} is in modal layer context or not
	 */
	isAvailableSoonVehicle(vehicle) {
		return (vehicle?.availableFromCode === 'date' && ((vehicle?.dealer?.id === '03611') || SETUPS.get('scopes.show.available.soon.date')));
	}

	/**
	 * get url parameter by name
	 * @method getUrlParameterByName
	 * @param {string} name - get parameter
	 * @param {string} url - url
	 * @returns {string} values of get parameter
	 */
	getUrlParameterByName(name = '', url = '') {
		const _name = name.replace(/[[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + _name + '(=([^&#]*)|&|#|$)');
		const results = regex.exec(url);

		if (!results) {
			return null;
		}

		return !results[2]
			? ''
			: decodeURIComponent((results[2] || '').replace(/\+/g, ' '));
	}

	/**
	 * getMarket from setups
	 * @return {string} market
	 */
	getMarket() {
		return SETUPS.get('stockcar.scs.market.path');
	}

	/**
	 * getStartpage from setups
	 * @returns {string} startpage
	 */
	getStartPage() {
		return SETUPS.get('stockcar.url.startpage');
	}

	/**
	 * getSeperatingElement
	 * get the seperating element depending
	 * if hash or question mark is index of href
	 * @param {string} url url to be checked
	 * @return {string} seperating element
	 */
	getSeparatingElement(url = window.location.href) {
		if (url.indexOf('?') > -1 || url.indexOf('#') > -1) {
			return '&';
		}
		return '?';
	}

	getMergedGetParamsUrl(url = '') {
		const regEx = /(.*?)([#|?])(.*)/;
		const urlParts = regEx.exec(url);

		if (!urlParts || urlParts.length < 4) {
			console.error('Url has no paramString');
			return url;
		}

		const urlHref = urlParts[1];
		const paramsStartElement = urlParts[2];
		const paramsString = urlParts[3];
		const searchParams = new URLSearchParams(paramsString);
		const mergedSearchParams = new URLSearchParams('');

		for (let key of searchParams.keys()) {
			const entries = searchParams.getAll(key);
			mergedSearchParams.set(key, entries.join(','));
		}

		return decodeURIComponent(urlHref + paramsStartElement + mergedSearchParams.toString());
	}

	getVehicleIdFromUrl(url_ = location.href) {
		let regex = /(sc_detail.*?\.)(([^.\s])+)/gi,
			matchesArr,
			vehicleId,
			vehicleIds = [];
		// loop all matches and push id into vehicleIds array
		while ((matchesArr = regex.exec(location.href))) {
			vehicleIds.push(matchesArr[2]);
		}
		// return only the last id - needed for detail page in layer on top of detail page
		vehicleId = vehicleIds[vehicleIds.length - 1] || '';
		// if url has param vehicleid, use this vehicleid instead of sc_detail vehicleid
		let match = url_.match(/(vehicleid=)(\D|\w)+&*/gi);
		if (!!match && match.length) {
			match = (match[0] || '').replace(/vehicleid=/, '');
			match = match.split('&').shift();
			vehicleId = match;
		}
		return vehicleId;
	}

	getVehicleId() {
		let vehicleId = '';
		if (!!this.data && !!this.data.targetElement && !!this.data.targetElement.closest('[data-vehicle-id]')) {
			vehicleId = this.data.targetElement.closest('[data-vehicle-id]').dataset.vehicleId;
		}
		else {
			vehicleId = this.getVehicleIdFromUrl();
		}
		return vehicleId;
	}

	createPostFormData(id = null) {
		const vehicleId = id || this.getVehicleId();
		const vehicle = SELECTORS.VEHICLES.getVehiclesMapByIds(STCK_STORE.state, [vehicleId]).get(vehicleId);
		const products = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, vehicleId);
		const financing = SELECTORS.FINANCING.getSummaryOnePointFive(STCK_STORE.state, vehicleId);
		const vehicleData = SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(vehicleId);
		const postData = [
			{'aoz': products},
			{'audicode': vehicle?.audiCode},
			{'financingData': financing},
			{'vehicleData': vehicleData}
		];
		return encodeURIComponent(JSON.stringify(postData));
	}
}

const scPage = new ScPageClass();

export {scPage, ScPageClass};
